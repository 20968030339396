import { Steps } from "antd";
import { isMobile } from 'react-device-detect';
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

import './StepsContainer.css';
import { iPage } from "../api";

//-------------------------------------------------------------------
export interface StepsContainerProps {
	pages: iPage[],
	activePageIdx: number,
	onChangeActivePageIdx: (idx: number) => void
};

//-------------------------------------------------------------------
export const StepsContainer: React.FC<StepsContainerProps> = props => {

	const onChange = (value: number) => {
		console.log('onChange:', value);
		props.onChangeActivePageIdx(value);
	};

	const handleGoPrev = () => {
		if(props.activePageIdx > 0)
			props.onChangeActivePageIdx(props.activePageIdx - 1);
	};

	const handleGoNext = () => {
		props.onChangeActivePageIdx(props.activePageIdx + 1);
	};

	if(isMobile)
		return (
			<div className="MobileStepsCotainer">
				<LeftCircleOutlined style={{ fontSize: '3em', color: '#999', margin: '0 2vw' }} onClick={handleGoPrev} />
				<RightCircleOutlined style={{ fontSize: '3em', color: '#999', margin: '0 2vw' }} onClick={handleGoNext} />
			</div>
			);
	else
		return (
			<div className="StepsContainer">
				<Steps
					current={props.activePageIdx}
					onChange={onChange}
					direction="horizontal"
					size="small"
					labelPlacement="vertical"
					items={props.pages.map((p) => {
						return {
							title: p.title,
							description: "",
						};
					})} />		
			</div>
		);
};

StepsContainer.defaultProps = {};