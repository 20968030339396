import React, { useEffect, useState } from 'react';
import {isMobile} from 'react-device-detect';
import './App.css';
import { PageContainer } from './components/PageContainer';
import { StepsContainer } from './components/StepsContainer';
import { debugInitPages, getPage, getPages, iPage, setTodayPixel } from './api';

function App() {
	const [activePage, setActivePage] = useState<iPage|null>(null);
	const [activePageIdx, setActivePageIdx] = useState<number>(-1);

	useEffect(() => {
		debugInitPages();
		const currPage = getPage(0);
		setActivePageIdx(0);
		setActivePage(currPage);
	}, []);

	const pages = getPages();

	const handleChangeActivePageIdx = (idx: number) => {
		if(idx !== activePageIdx && idx >= 0 && idx < pages.length)
		{
			setActivePageIdx(idx);
			const currPage = getPage(idx);
			setActivePage(currPage);
		}
	};

	const hanldeOnSaveTodayPixel = (optionId: number) => {
		if(activePage)
		{
			setTodayPixel(activePage?.id, optionId);
			const currPage = getPage(activePageIdx);
			setActivePage(currPage);
		}
	};

	return (
		<div className={"App" + (isMobile ? " isMobile" : "")}>
			{(activePageIdx === -1 ? 
				<div>Loading</div> :
				<PageContainer page={activePage} pages={pages} activePageIdx={activePageIdx} 
					onChangeActivePageIdx={handleChangeActivePageIdx} 
					onSaveTodayPixel={hanldeOnSaveTodayPixel} />)}
		</div>
	);
}

export default App;
