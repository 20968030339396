import * as React from "react";
import CSS from 'csstype';
import "./Title.css"

export interface iTitleProps {
	pageTitle: string
};

export const Title: React.FC<iTitleProps> = props => {
	return (
		<div className="Title">{props.pageTitle}</div>
	);
};

Title.defaultProps = {};