import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {} from '@fortawesome/free-regular-svg-icons';
import {faPencil} from '@fortawesome/free-solid-svg-icons';

import { iPageOptions, iPixel } from "../../../api";

import CSS from 'csstype';
import "./Pixel.css"


export interface iPixelProps {
	item: iPixel,
	option: iPageOptions | null,
	onTodayClick: () => void
};

export const Pixel: React.FC<iPixelProps> = props => {

	const style: CSS.Properties = {
		//backgroundColor: props.colour
	};

	const classes = ["Pixel"];

	if(props.item.bFilled)
	{
		style.backgroundColor = props.option?.colour;
		classes.push("Filled");
	}
	if(props.item.bToday)
		classes.push("Today");
	if(props.item.bNotExistingDay)
		classes.push("NotExistingDay");

	let bClickable = false;
	let icon = null;
	if(props.item.bToday && !props.item.bFilled && !props.item.bNotExistingDay)
	{
		classes.push("Editable");
		icon = <FontAwesomeIcon icon={faPencil} />;
		bClickable = true;
	}

	const handleClick = () => {
		if(bClickable)
			props.onTodayClick();
	};

	return (
		<div className={classes.join(" ")} style={style} onClick={handleClick}>{icon}</div>
	);
};

Pixel.defaultProps = {};