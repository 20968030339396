import { PixelJournalPage } from "./PixelJournalPage";
import { iPage } from "../api";
import './PageContainer.css';
import { StepsContainer } from "./StepsContainer";

//-------------------------------------------------------------------
export interface PageContainerProps {
	page: iPage | null,
	pages: iPage[],
	activePageIdx: number,
	onChangeActivePageIdx: (idx: number) => void,
	onSaveTodayPixel: (i: number) => void
};

//-------------------------------------------------------------------
export const PageContainer: React.FC<PageContainerProps> = props => {

	return (
		<div className="PageContainer">
			<PixelJournalPage page={props.page} onSaveTodayPixel={props.onSaveTodayPixel} />
			<StepsContainer 
				pages={props.pages} activePageIdx={props.activePageIdx} 
				onChangeActivePageIdx={props.onChangeActivePageIdx} />			
		</div>
	);
};

PageContainer.defaultProps = {};