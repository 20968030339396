import { useState } from "react";
import { isMobile } from 'react-device-detect';
import { iPage, setTodayPixel } from "../../api";

import { PixelTable } from "./PixelTable/PixelTable";
import { Title } from "./Title";
import { Legend } from "./Legend";

import "./PixelJournalPage.css"

//-------------------------------------------------------------------
export interface PixelJournalPageProps {
	page: iPage | null,
	onSaveTodayPixel: (i : number) => void
};

//-------------------------------------------------------------------
export const PixelJournalPage: React.FC<PixelJournalPageProps> = props => {

	const [chivato, setChivato] = useState(0);
	
	const handleSaveTodayPixel = (optionId: number) => {
		if(props.page)
		{
			setTodayPixel(props.page?.id, optionId);
			setChivato(chivato+1);
		}
	};

	if(!props.page)
		return null;
	else
	{
		if(isMobile)
			return (
				<div className="PixelJournalPage">
					<div className="PJPBackground"></div>
					<Title pageTitle={props.page.title} />
					<div className="MobileGroup">
						<PixelTable pixels={props.page.pixels} options={props.page.options} 
							bTodayPixelPending={!props.page.bFilledToday} onSaveTodayPixel={props.onSaveTodayPixel}  />
						<Legend options={props.page.options} />
					</div>
				</div>
			);
		else
			return (
				<div className="PixelJournalPage">
					<div className="PJPBackground"></div>
					<Title pageTitle={props.page.title} />
					<PixelTable pixels={props.page.pixels} options={props.page.options} 
						bTodayPixelPending={!props.page.bFilledToday} onSaveTodayPixel={props.onSaveTodayPixel}  />
					<Legend options={props.page.options} />
				</div>
			);
	}
};

PixelJournalPage.defaultProps = {};
