import * as React from "react";

import { iPageOptions } from "../../api";

import CSS from 'csstype';
import "./Legend.css"

export interface iLegendProps {
	options: iPageOptions[]
};

export const Legend: React.FC<iLegendProps> = props => {
	return (
		<div className="Legend">
			{props.options.map((o, idx) => {
				const style: CSS.Properties = {
					backgroundColor: o.colour
				};

				return (<div className="LegendItem" key={"LegendItem"+idx}>
					<div className="LegendItemColour" style={style}></div>
					<div className="LegendItemLabel">{o.label}</div>
				</div>);
			})}
		</div>
	);
};

Legend.defaultProps = {};