import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';

import { iPageOptions, iPixel } from "../../../api";

import CSS from 'csstype';

import "./TodayFiller.css"


export interface iTodayFillerProps {
	options: iPageOptions[],
	onClickClose: () => void,
	onClickAccept: (i: number) => void
};

export const TodayFiller: React.FC<iTodayFillerProps> = props => {
	const [todaySelectedOption, setTodaySelectedOption] = useState<number>(-1);

	const handleOptionClick = (optionId: number) => {
		setTodaySelectedOption(optionId);
	};	

	const handleCloseClick = () => {
		props.onClickClose();
	};

	const handleAcceptClick = () => {
		if(todaySelectedOption !== -1)
			props.onClickAccept(todaySelectedOption);
	};

	return (
		<div className="TodayFillerContainer">
			<div className="TodayFillerCard">
				<div className="TodayFillerCardText">Elige la opción para hoy:</div>
				<div className="TodayFillerCardOptionList">
				{
					props.options.map(option => {
						const style: CSS.Properties = {
							backgroundColor: option.colour
						};

						let optionClasses = ["OptionToChose"];
						if(todaySelectedOption === option.id)
							optionClasses.push("Selected");
						else
							optionClasses.push("NotSelected");

						return <div className={optionClasses.join(" ")} key={"TodayFillerOpt"+option.id}
									style={style} onClick={() => handleOptionClick(option.id)}><span>{option.label}</span></div>
					})
				}
				</div>
				<div className="TodayFillerCardButtons">
					<div className={"CircularButton AcceptButton " + (todaySelectedOption === -1 ? "DisabledButton" : "")}
							onClick={handleAcceptClick}>
						<FontAwesomeIcon icon={faCheck} /></div>
					<div className="CircularButton CancelButton" 
							onClick={handleCloseClick}>
						<FontAwesomeIcon icon={faXmark} /></div>
				</div>
			</div>
		</div>
	);
};

TodayFiller.defaultProps = {};