import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {} from '@fortawesome/free-regular-svg-icons';
import {faCheck,faXmark} from '@fortawesome/free-solid-svg-icons';

import { iPixel, iPageOptions } from "../../../api";
import { Pixel } from "./Pixel";
import { TodayFiller } from "./TodayFiller";

import "./PixelTable.css"

//-------------------------------------------------------------------
const getMonthList = (locales?: string | string[], format: "long" | "short" = "long") : string[] =>  {
	const year = new Date().getFullYear(); // 2020
	const monthList = [];
	for(let i = 0; i < 12; i++)
		monthList.push(i);
	const formatter = new Intl.DateTimeFormat(locales, {
		month: format
	});

	const getMonthName = (monthIndex: number) =>
		formatter.format(new Date(year, monthIndex));

	return monthList.map(getMonthName);
};

//-------------------------------------------------------------------
const getDayList = () : string[] => {
	const dayList = [];
	for(let i = 1; i < 32; i++)
		dayList.push(i.toString());
	return dayList;
};

//-------------------------------------------------------------------
export interface PixelTableProps {
	pixels: iPixel[][],
	options: iPageOptions[],
	bTodayPixelPending: Boolean,
	onSaveTodayPixel: (i: number) => void
};

//-------------------------------------------------------------------
export const PixelTable: React.FC<PixelTableProps> = props => {

	const [todayFillerVisible, setTodayFillerVisible] = useState(false);

	const monthList = getMonthList();
	const dayList = getDayList();

	const getPixelOption = (optionId: number) => {
		const o = props.options.find(o => o.id === optionId);
		return o || null;
	};

	const handleTodayPixelClick = () => {
		if(props.bTodayPixelPending)
			setTodayFillerVisible(true);
	};

	const handleCloseTodayFiller = () => {
		setTodayFillerVisible(false);
	};

	const handleSaveTodayFiller = (optionId: number) => {
		if(props.bTodayPixelPending)
			props.onSaveTodayPixel(optionId);
		setTodayFillerVisible(false);
	};	

	return (
		<div className="PixelTable">
			<div className="PixelTablePixels">
				<div className="PixelTableDays">
					<div className="PixelTableDayItem" key={"DayNane_empty"}>{` `}</div>
					{dayList.map((d, idx) => {
						return <div className="PixelTableDayItem" key={"DayNane"+idx}>{d}</div>;
					})}
				</div>			
				{props.pixels.map((monthlyPixels, midx) => {
					return (
					<div className="PixelTablePixelsMonth" key={"PixelMonth"+midx}>
						<div className="PixelTableDayItem">{monthList[midx].substring(0,1)}</div>
						{monthlyPixels.map((pixel, idx) => {
							return <Pixel 
										item={pixel} option={getPixelOption(pixel.optionId)}  
										key={"Pixel"+midx+"_"+idx} onTodayClick={handleTodayPixelClick} />;
						})}
					</div>);
				})}
			</div>
			{todayFillerVisible && 
				<TodayFiller options={props.options} onClickClose={handleCloseTodayFiller} onClickAccept={handleSaveTodayFiller} />}
		</div>
	);
};

PixelTable.defaultProps = {};