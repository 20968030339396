import personalPagesDef from './personal.json';
import professionalPagesDef from './professional.json';

export interface iPageOptions {
	id: number,
	colour: string,
	label: string
};

export interface iPixel {
	day: string,
	bFilled: Boolean,
	bNotExistingDay: Boolean,
	bToday: Boolean,
	optionId: number,
	notes: String
};

export interface iPage {
	id: number,
	title: string,
	options: iPageOptions[],
	pixels: iPixel[][],
	bFilledToday: Boolean
};

let pages: iPage[] = professionalPagesDef.pages;

//-------------------------------------------------------------------
const isLeapYear = function (year: number): Boolean { 
	return ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0); 
};

//-------------------------------------------------------------------
const getDaysInMonth = function (year: number, month: number): number {
	return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

//-------------------------------------------------------------------
export const debugInitPages = () => {

	const today = new Date(Date.now());
	const year = today.getFullYear();
	const todayDayOfMonth = today.getDate() - 1;

	pages.forEach((page, idx) => {
		let bFilled = true; // TODO: Get from API
		for(let i = 0; i < 12; i++)
		{
			let monthlyPixels: iPixel[] = [];
			for(let j = 0; j < 31; j++)
			{
				const daysInMonths = getDaysInMonth(year, i);
				const bNotExistingDay = j + 1 > daysInMonths;
				const bToday = (i === today.getMonth() && j === todayDayOfMonth);

				if(bToday && bFilled)
					bFilled = false;				

				monthlyPixels.push({
					day: `{year}-{i.toString().padStart(2,'0')}-{j.toString().padStart(2,'0')}`,
					bFilled: bFilled && !bNotExistingDay,
					bNotExistingDay,
					bToday,
					optionId: bFilled ? Math.floor(Math.random() * 5) + 1 : -1, // TODO: Get from API
					notes: ""
				});
			}

			pages[idx].pixels[i] = monthlyPixels;
		}
	});
};

//-------------------------------------------------------------------
export const getPages = (): iPage[]  => {
	return pages;
};

//-------------------------------------------------------------------
export const getNPages = (): number => {
	return pages.length;
};

//-------------------------------------------------------------------
export const getPage = (idx: number): iPage => {
	return pages[idx];
};

//-------------------------------------------------------------------
export const editPage = (id: number, pageData: iPage) => {
	let pageIdx = pages.findIndex((el: iPage) => el.id === id);
	if(pageIdx)
		pages[pageIdx] = JSON.parse(JSON.stringify(pageData));
};

//-------------------------------------------------------------------
export const setTodayPixel = (id: number, optionId: number) => {
	let pageIdx = pages.findIndex((el: iPage) => el.id === id);
	if(pageIdx !== null)
	{
		const today = new Date(Date.now());
		const month = today.getMonth();
		const todayDayOfMonth = today.getDate() - 1;
		if(pages[pageIdx].pixels[month][todayDayOfMonth].bToday)
		{
			pages[pageIdx].pixels[month][todayDayOfMonth].bFilled = true;
			pages[pageIdx].pixels[month][todayDayOfMonth].optionId = optionId;
		}
	}
};